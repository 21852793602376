import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import StockistComponent from 'components/Stockist'

import s from './styles.module.css'

const Stockist = ({ data, locatorPage, pageContext }) => {
  const company = data.hubspotCompanies
  return (
    <Layout {...pageContext} className={s.container}>
      <SEO
        title={`${company.properties?.name} | Chalk UK`}
        description={company.properties?.about_us}
      />
      <StockistComponent
        contentClassName={s.content}
        locatorPage={locatorPage}
        id={company.id}
        {...company.properties}
      />
    </Layout>
  )
}

Stockist.propTypes = {
  data: PropTypes.object,
  locatorPage: PropTypes.bool,
  pageContext: PropTypes.object,
}

export default Stockist

export const pageQuery = graphql`
  query HubspotCompanyById($id: String!) {
    hubspotCompanies(id: { eq: $id }) {
      id
      properties {
        stockist_visibility
        preferred_partner
        name
        email
        image_url
        longitude_latitude
        address
        address2
        city
        state
        zip
        about_us
        services
        opening_times
        collections_stocked
        phone
        domain
        instagram
      }
    }
  }
`
